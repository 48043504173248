const video = [
    "vid-0",
    "vid-1",
    "vid-2",
    "vid-3",
    "vid-4",
    "vid-5",
    "vid-6",
    "vid-7",
    "vid-8",
    "vid-9",
    "vid-10",
    "vid-11",
    "vid-12",
    "vid-13",
    "vid-14",
    "vid-15",
    "vid-16",
    "vid-17",
    "vid-18",
    "vid-19",
    "vid-20",
    "vid-21",
    "vid-23",
    "vid-24",
    "vid-25",
    "vid-26",
    "vid-27",
    "vid-28",
    "vid-29",
    "vid-30",
    "vid-31",
    "vid-32",
    "vid-33",
    "vid-34",
    "vid-35",
    "vid-36",
    "vid-37",
]

export default video