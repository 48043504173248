const churches = [
    "Non-Affiliated (NA)",
    "1WEST1 (Messianic, Harlem-School Influenced) CAMPS",
    "Ambassadors of Christ (AOC)",
    "Army of Israel (AOI)",
    "Believers of The Way",
    "Church of God and Saints of Christ (COGASOC)",
    "Future World of Israel (FWOI)",
    "Gathering of Christ Church (GOCC)",
    "Great MillStone Israelites (GMS)",
    "House of Israel (HOI)",
    "Israel United in Christ (IUIC)",
    "Israelite Church of God in Jesus Christ (ICGJC)",
    "Israelite School of Biblical & Practical Knowledge (ISBPK)",
    "Israelite School of Knowledge (ISOK)",
    "Israelite School of Universal Practical Knowledge (ISUPK)",
    "Lions of Israel / We Got Next (LOI)",
    "Nation of Yahweh (NOY)",
    "Onebody Israelites",
    "Sons of Thunder (SOT)",
    "Shut ‘Em Down Crew / RAM Squad (RAM)",
    "Sicarii",
    "TANAKH-Only (TO)",
    "Thee Light of Zion (TLOZ)",
    "True Nation Israelite (TNI)",
    "United Kingdom of Israel (UKIOC)",
    "We Got Next (WGN )",
    "Watchmen For Israel (WFI)",
    "Yahawashi’s Servants in Y’Sharael (YSIY)",
    "Others"
]

export default churches