window.sample = null
window.data = []
window.gameStarted = false
window.keysAllowed = false
window.countdownID = null
window.solved = []
window.bgNum = 2
window.skips = 0

window.body = document.querySelector('body')
window.container = document.querySelector('#container')
window.cells = document.querySelectorAll('.cell')
window.inputString = document.querySelector('#inputstring')
window.alphaKeys = document.querySelectorAll('.alphabetickey')
window.backspaceKeyImg = document.querySelector('#backspacekey')
window.spaceKeyImg = document.querySelector('#spacekey')
window.scoreText = document.querySelector('#scoreText')
window.scoreValue = document.querySelector('#scoreValue')
window.countdown = document.querySelector('#countdown')
window.bgMusic = document.getElementById("bgMusic")

// window.dictionary = [
//    "a",
//    "them","and","god","will","Thee","aree","out","had",
//    "into","come","now","things","let","your","ours","earth","did","over","brought","city","father","days","set","according","thereof","two","thus","again","about","many","done","see","spake","himself","might","high","cast","ever","eyes","would","there","glory","with","peace","where","sin","keep","left","woman","dead","year","right"
// ]

window.dictionary = [
    "aaron",
    "aaron's",
    "aaronites",
    "abarim",
    "abase",
    "abased",
    "abashed",
    "abated",
    "abateth",
    "abba",
    "abda",
    "abdi",
    "abdon",
    "abednego",
    "abel",
    "abelbethmaachah",
    "abelmeholah",
    "abhor",
    "abhorred",
    "abhorrest",
    "abhorreth",
    "abia",
    "abiah",
    "abiathar",
    "abib",
    "abidan",
    "abide",
    "abideth",
    "abiding",
    "abiel",
    "abiezer",
    "abiezrites",
    "abigail",
    "abihail",
    "abihu",
    "abijah",
    "abijam",
    "ability",
    "abimael",
    "abimelech",
    "abimelech's",
    "abinadab",
    "abinoam",
    "abiram",
    "abishag",
    "abishai",
    "abishalom",
    "abishua",
    "abishur",
    "abital",
    "able",
    "abner",
    "abode",
    "abolish",
    "abolished",
    "abominable",
    "abomination",
    "abominations",
    "abound",
    "abounded",
    "aboundeth",
    "abounding",
    "about",
    "above",
    "abraham",
    "abraham's",
    "abram",
    "abram's",
    "abroad",
    "absalom",
    "absalom's",
    "absence",
    "absent",
    "absolom",
    "abstain",
    "abstinence",
    "abubus",
    "abundance",
    "abundant",
    "abundantly",
    "abuse",
    "abused",
    "accept",
    "acceptable",
    "acceptation",
    "accepted",
    "accepteth",
    "accepting",
    "access",
    "accompanied",
    "accompany",
    "accomplish",
    "accomplished",
    "accord",
    "according",
    "accordingly",
    "account",
    "accounted",
    "accounts",
    "accursed",
    "accusation",
    "accusations",
    "accuse",
    "accused",
    "accuser",
    "accusers",
    "accustomed",
    "achaia",
    "achan",
    "achbor",
    "achiacharus",
    "achior",
    "achish",
    "achitob",
    "achor",
    "achsah",
    "achshaph",
    "achzib",
    "acknowledge",
    "acknowledged",
    "acknowledging",
    "acquaint",
    "acquaintance",
    "acquainted",
    "acquit",
    "act",
    "actions",
    "acts",
    "adah",
    "adaiah",
    "adam",
    "adamant",
    "adar",
    "adasa",
    "adbeel",
    "add",
    "added",
    "adder",
    "addeth",
    "addi",
    "additions",
    "addus",
    "adida",
    "adiel",
    "adin",
    "adjoining",
    "adjure",
    "adjured",
    "admah",
    "administered",
    "admiration",
    "admired",
    "admonish",
    "admonished",
    "admonition",
    "adna",
    "adnah",
    "ado",
    "adonibezek",
    "adonijah",
    "adonikam",
    "adoniram",
    "adonizedec",
    "adoption",
    "adoram",
    "adorn",
    "adorned",
    "adorning",
    "adrammelech",
    "adriel",
    "adullam",
    "adullamite",
    "adulterer",
    "adulterers",
    "adulteress",
    "adulteresses",
    "adulteries",
    "adulterous",
    "adultery",
    "adummim",
    "advanced",
    "advantage",
    "adventure",
    "adversaries",
    "adversary",
    "adversities",
    "adversity",
    "advertise",
    "advice",
    "advise",
    "advised",
    "advisement",
    "aeneas",
    "afar",
    "affairs",
    "affect",
    "affected",
    "affection",
    "affections",
    "affinity",
    "affirm",
    "affirmed",
    "afflict",
    "afflicted",
    "affliction",
    "afflictions",
    "affrighted",
    "afoot",
    "afore",
    "aforehand",
    "aforesaid",
    "aforetime",
    "afraid",
    "afterward",
    "afterwards",
    "agabus",
    "agag",
    "agagite",
    "again",
    "agar",
    "agate",
    "age",
    "aged",
    "ages",
    "aggeus",
    "ago",
    "agony",
    "agree",
    "agreed",
    "agreement",
    "agreeth",
    "agrippa",
    "aha",
    "ahab",
    "ahab's",
    "ahasuerus",
    "ahava",
    "ahaz",
    "ahaziah",
    "ahi",
    "ahiah",
    "ahiam",
    "ahiezer",
    "ahihud",
    "ahijah",
    "ahikam",
    "ahilud",
    "ahimaaz",
    "ahiman",
    "ahimelech",
    "ahinoam",
    "ahio",
    "ahira",
    "ahisamach",
    "ahithophel",
    "ahitub",
    "ahlai",
    "ahohite",
    "aholah",
    "aholiab",
    "aholibah",
    "aholibamah",
    "aiah",
    "aid",
    "aijalon",
    "ail",
    "aileth",
    "ain",
    "air",
    "ajalon",
    "akkub",
    "akrabbim",
    "alabaster",
    "alarm",
    "alas",
    "albeit",
    "alcimus",
    "alemeth",
    "alexander",
    "alexander's",
    "alexandria",
    "algum",
    "alien",
    "alienated",
    "aliens",
    "alike",
    "alive",
    "alleging",
    "alleluia",
    "allied",
    "allom",
    "allon",
    "allonbachuth",
    "allow",
    "allowance",
    "allowed",
    "alloweth",
    "allure",
    "allured",
    "almighty",
    "almodad",
    "almon",
    "almond",
    "almondiblathaim",
    "almonds",
    "almost",
    "alms",
    "almsdeeds",
    "almug",
    "alnathan",
    "aloes",
    "aloft",
    "alone",
    "along",
    "aloof",
    "aloth",
    "aloud",
    "alpha",
    "alphaeus",
    "already",
    "altaneus",
    "altar",
    "altars",
    "alter",
    "alterations",
    "altered",
    "altereth",
    "although",
    "altogether",
    "alush",
    "alvah",
    "alvan",
    "alway",
    "always",
    "amad",
    "amadatha",
    "amadathus",
    "amain",
    "amal",
    "amalek",
    "amalekite",
    "amalekites",
    "amam",
    "aman",
    "aman's",
    "amana",
    "amariah",
    "amarias",
    "amasa",
    "amasai",
    "amashai",
    "amasiah",
    "amatheis",
    "amathis",
    "amazed",
    "amazement",
    "amaziah",
    "ambasadors",
    "ambassador",
    "ambassadors",
    "ambassage",
    "amber",
    "ambush",
    "ambushes",
    "ambushment",
    "ambushments",
    "amen",
    "amend",
    "amendment",
    "amends",
    "amerce",
    "amethyst",
    "ami",
    "amiable",
    "amidst",
    "aminadab",
    "amiss",
    "amittai",
    "amity",
    "ammah",
    "ammi",
    "ammidoi",
    "ammiel",
    "ammihud",
    "amminadab",
    "amminadib",
    "ammishaddai",
    "ammizabad",
    "ammon",
    "ammonite",
    "ammonites",
    "ammonitess",
    "amnon",
    "amnon's",
    "amok",
    "amon",
    "among",
    "amongst",
    "amorite",
    "amorites",
    "amos",
    "amounting",
    "amoz",
    "amphipolis",
    "amplias",
    "amram",
    "amram's",
    "amramites",
    "amraphel",
    "amzi",
    "anab",
    "anael's",
    "anah",
    "anaharath",
    "anaiah",
    "anak",
    "anakims",
    "anamim",
    "anammelech",
    "anan",
    "anani",
    "ananiah",
    "ananias",
    "ananiel",
    "anath",
    "anathema",
    "anathoth",
    "ancestors",
    "ancestors'",
    "anchor",
    "anchors",
    "ancient",
    "ancients",
    "andrew",
    "andronicus",
    "anem",
    "aner",
    "anethothite",
    "anetothite",
    "anew",
    "angel",
    "angel's",
    "angels",
    "angels'",
    "anger",
    "angered",
    "angereth",
    "angle",
    "angry",
    "anguish",
    "aniam",
    "anim",
    "anise",
    "ankle",
    "ankles",
    "anna",
    "annas",
    "annointedst",
    "annoyance",
    "annoyed",
    "annus",
    "anoint",
    "anointed",
    "anointedst",
    "anointest",
    "anointing",
    "anon",
    "anos",
    "another",
    "another's",
    "answer",
    "answerable",
    "answered",
    "answeredst",
    "answerest",
    "answereth",
    "answering",
    "answers",
    "ant",
    "antichrist",
    "antichrists",
    "antilibanus",
    "antioch",
    "antiochia",
    "antiochians",
    "antiochus",
    "antiochus'",
    "antipas",
    "antipater",
    "antipatris",
    "antiquity",
    "antothijah",
    "antothite",
    "ants",
    "anub",
    "anus",
    "anvil",
    "any",
    "anything",
    "apace",
    "apame",
    "apart",
    "apelles",
    "apes",
    "apharsachites",
    "apharsathchites",
    "apharsites",
    "aphek",
    "aphekah",
    "apherema",
    "apherra",
    "aphiah",
    "aphik",
    "aphrah",
    "aphses",
    "apiece",
    "aplace",
    "apollonia",
    "apollonius",
    "apollonius'",
    "apollophanes",
    "apollos",
    "apollyon",
    "apostle",
    "apostles",
    "apostles'",
    "apostleship",
    "apothecaries",
    "apothecaries'",
    "apothecary",
    "appaim",
    "apparel",
    "apparelled",
    "apparently",
    "apparition",
    "apparitions",
    "appeal",
    "appealed",
    "appear",
    "appearance",
    "appearances",
    "appeared",
    "appeareth",
    "appearing",
    "appease",
    "appeased",
    "appeaseth",
    "appertain",
    "appertained",
    "appertaineth",
    "appertaining",
    "appetite",
    "appetites",
    "apphia",
    "apphus",
    "appii",
    "apple",
    "apples",
    "applied",
    "applieth",
    "apply",
    "appoint",
    "appointed",
    "appointedst",
    "appointeth",
    "appointment",
    "apprehend",
    "apprehended",
    "approach",
    "approached",
    "approacheth",
    "approaching",
    "approve",
    "approved",
    "approvest",
    "approveth",
    "approving",
    "aprons",
    "apt",
    "aquila",
    "ara",
    "arab",
    "arabah",
    "arabattine",
    "arabia",
    "arabian",
    "arabians",
    "arad",
    "aradus",
    "arah",
    "aram",
    "aramitess",
    "aran",
    "ararat",
    "ararath",
    "araunah",
    "arba",
    "arbah",
    "arbathite",
    "arbattis",
    "arbela",
    "arbite",
    "arbonai",
    "archangel",
    "archelaus",
    "archer",
    "archers",
    "arches",
    "archevites",
    "archi",
    "archippus",
    "archite",
    "arcturus",
    "ard",
    "ardath",
    "ardites",
    "ardon",
    "areli",
    "arelites",
    "areopagite",
    "areopagus",
    "ares",
    "aretas",
    "areus",
    "argob",
    "arguing",
    "arguments",
    "ariarathes",
    "aridai",
    "aridatha",
    "arieh",
    "ariel",
    "aright",
    "arimathaea",
    "arioch",
    "arisai",
    "arise",
    "ariseth",
    "arising",
    "aristarchus",
    "aristobulus",
    "aristobulus'",
    "ark",
    "arkite",
    "arm",
    "armageddon",
    "armed",
    "armenia",
    "armholes",
    "armies",
    "armoni",
    "armour",
    "armourbearer",
    "armoury",
    "arms",
    "army",
    "arnan",
    "arnon",
    "arod",
    "arodi",
    "arodites",
    "aroer",
    "aroerite",
    "arom",
    "arose",
    "arpad",
    "arphad",
    "arphaxad",
    "array",
    "arrayed",
    "arrived",
    "arrogancy",
    "arrow",
    "arrows",
    "arsaces",
    "arsareth",
    "art",
    "artaxerxes",
    "artaxerxes'",
    "artemas",
    "artexerxes",
    "articles",
    "artificer",
    "artificers",
    "artillery",
    "arts",
    "aruboth",
    "arumah",
    "arvad",
    "arvadite",
    "arza",
    "asa",
    "asa's",
    "asadias",
    "asael",
    "asahel",
    "asahiah",
    "asaiah",
    "asana",
    "asaph",
    "asaph's",
    "asareel",
    "asarelah",
    "ascalon",
    "ascend",
    "ascended",
    "ascendeth",
    "ascending",
    "ascent",
    "ascents",
    "ascribe",
    "ascribed",
    "aseas",
    "asebebia",
    "asebia",
    "asenath",
    "aser",
    "aserer",
    "ash",
    "ashamed",
    "ashan",
    "ashbea",
    "ashbel",
    "ashbelites",
    "ashchenaz",
    "ashdod",
    "ashdodites",
    "ashdothites",
    "ashdothpisgah",
    "asher",
    "asherites",
    "ashes",
    "ashima",
    "ashkelon",
    "ashkenaz",
    "ashnah",
    "ashpenaz",
    "ashriel",
    "ashtaroth",
    "ashterathite",
    "ashteroth",
    "ashtoreth",
    "ashur",
    "ashurites",
    "ashvath",
    "asia",
    "asibias",
    "aside",
    "asiel",
    "asipha",
    "ask",
    "asked",
    "askelon",
    "askest",
    "asketh",
    "asking",
    "asleep",
    "asmodeus",
    "asnah",
    "asnapper",
    "asom",
    "asp",
    "aspalathus",
    "aspatha",
    "asphar",
    "aspharasus",
    "asps",
    "asrias",
    "asriel",
    "asrielites",
    "ass",
    "ass's",
    "assabias",
    "assalimoth",
    "assanias",
    "assault",
    "assaulted",
    "assaulting",
    "assay",
    "assayed",
    "assaying",
    "assemble",
    "assembled",
    "assemblies",
    "assembling",
    "assembly",
    "assent",
    "assented",
    "asses",
    "asshur",
    "asshurim",
    "assideans",
    "assign",
    "assigned",
    "assir",
    "assist",
    "assisting",
    "associate",
    "assos",
    "assuerus",
    "assur",
    "assurance",
    "assure",
    "assured",
    "assuredly",
    "asswage",
    "asswaged",
    "assyria",
    "assyrian",
    "assyrians",
    "assyrians'",
    "astaroth",
    "astath",
    "astonied",
    "astonished",
    "astonishment",
    "astray",
    "astrologer",
    "astrologers",
    "astyages",
    "asunder",
    "asuppim",
    "asyncritus",
    "atad",
    "atarah",
    "atargatis",
    "ataroth",
    "atarothadar",
    "atarothaddar",
    "ate",
    "ater",
    "aterezias",
    "athach",
    "athaiah",
    "athaliah",
    "atharias",
    "athenians",
    "athenobius",
    "athens",
    "athirst",
    "athlai",
    "atipha",
    "atonement",
    "atonements",
    "atroth",
    "attai",
    "attain",
    "attained",
    "attalia",
    "attalus",
    "attempt",
    "attempted",
    "attempts",
    "attend",
    "attendance",
    "attended",
    "attendeth",
    "attending",
    "attent",
    "attentive",
    "attentively",
    "attharates",
    "attire",
    "attired",
    "audience",
    "augia",
    "augment",
    "augmentation",
    "augustus",
    "augustus'",
    "aul",
    "aunt",
    "auranus",
    "austere",
    "auteas",
    "author",
    "authorities",
    "authority",
    "authors",
    "ava",
    "availeth",
    "avaran",
    "aven",
    "avenge",
    "avenged",
    "avenger",
    "avengers",
    "avengeth",
    "avenging",
    "averse",
    "avim",
    "avims",
    "avites",
    "avith",
    "avoid",
    "avoided",
    "avoiding",
    "avouched",
    "await",
    "awake",
    "awaked",
    "awakest",
    "awaketh",
    "awaking",
    "aware",
    "awe",
    "awoke",
    "axe",
    "axes",
    "axletree",
    "axletrees",
    "azael",
    "azaelus",
    "azal",
    "azaliah",
    "azaniah",
    "azaphion",
    "azarael",
    "azaras",
    "azare",
    "azareel",
    "azariah",
    "azarias",
    "azaz",
    "azaziah",
    "azbazareth",
    "azbuk",
    "azekah",
    "azel",
    "azem",
    "azephurith",
    "azetas",
    "azgad",
    "azia",
    "aziei",
    "aziel",
    "aziza",
    "azmaveth",
    "azmon",
    "aznothtabor",
    "azor",
    "azotus",
    "azriel",
    "azrikam",
    "azubah",
    "azur",
    "azuran",
    "azzah",
    "azzan",
    "azzur",
    "baal",
    "baal's",
    "baalah",
    "baalath",
    "baalathbeer",
    "baalberith",
    "baale",
    "baalgad",
    "baalhamon",
    "baalhanan",
    "baalhazor",
    "baalhermon",
    "baali",
    "baalim",
    "baalis",
    "baalmeon",
    "baalpeor",
    "baalperazim",
    "baalshalisha",
    "baaltamar",
    "baalzebub",
    "baalzephon",
    "baana",
    "baanah",
    "baanaia",
    "baanias",
    "baara",
    "baaseiah",
    "baasha",
    "babai",
    "babbler",
    "babbling",
    "babblings",
    "babe",
    "babel",
    "babes",
    "babi",
    "babylon",
    "babylon's",
    "babylonians",
    "babylonish",
    "babylons",
    "baca",
    "bacchides",
    "bacchides'",
    "bacchurus",
    "bacchus",
    "bacenor's",
    "bachrites",
    "back",
    "backbiters",
    "backbiteth",
    "backbiting",
    "backbitings",
    "backbone",
    "backs",
    "backside",
    "backslider",
    "backsliding",
    "backslidings",
    "backward",
    "bad",
    "bade",
    "badest",
    "badgers'",
    "badness",
    "bag",
    "baggage",
    "bago",
    "bagoas",
    "bagoi",
    "bags",
    "baharumite",
    "bahurim",
    "bajith",
    "bakbakkar",
    "bakbuk",
    "bakbukiah",
    "bake",
    "baked",
    "bakemeats",
    "baken",
    "baker",
    "bakers",
    "bakers'",
    "baketh",
    "balaam",
    "balaam's",
    "balac",
    "baladan",
    "balah",
    "balak",
    "balak's",
    "balamo",
    "balance",
    "balances",
    "balancings",
    "balasamus",
    "bald",
    "baldness",
    "ball",
    "balls",
    "balm",
    "balnuus",
    "balthasar",
    "bamah",
    "bamoth",
    "bamothbaal",
    "ban",
    "banaias",
    "band",
    "banded",
    "bands",
    "bani",
    "banid",
    "banished",
    "banishment",
    "bank",
    "banks",
    "banner",
    "banners",
    "bannus",
    "banquet",
    "banqueted",
    "banqueting",
    "banquetings",
    "banuas",
    "baptism",
    "baptisms",
    "baptist",
    "baptist's",
    "baptize",
    "baptized",
    "baptizest",
    "baptizeth",
    "baptizing",
    "bar",
    "barabbas",
    "barachel",
    "barachias",
    "barak",
    "barbarian",
    "barbarians",
    "barbarous",
    "barbarously",
    "barbed",
    "barber's",
    "bare",
    "barefoot",
    "barest",
    "barhumite",
    "bariah",
    "barjesus",
    "barjona",
    "bark",
    "barked",
    "barkos",
    "barley",
    "barn",
    "barnabas",
    "barnfloor",
    "barns",
    "barodis",
    "barrel",
    "barrels",
    "barren",
    "barrenness",
    "bars",
    "barsabas",
    "bartacus",
    "bartholomew",
    "bartimaeus",
    "baruch",
    "barzelus",
    "barzillai",
    "basaloth",
    "bascama",
    "base",
    "baseness",
    "baser",
    "bases",
    "basest",
    "bashan",
    "bashanhavothjair",
    "bashemath",
    "bashfulness",
    "basket",
    "baskets",
    "basmath",
    "bason",
    "basons",
    "bassa",
    "bastai",
    "bastard",
    "bastards",
    "bat",
    "bath",
    "bathe",
    "bathed",
    "bathrabbim",
    "baths",
    "bathsheba",
    "bathshua",
    "bathzacharias",
    "bats",
    "battered",
    "battering",
    "battle",
    "battlement",
    "battlements",
    "battles",
    "bavai",
    "bay",
    "bazlith",
    "bazluth",
    "bdellium",
    "beacon",
    "bealiah",
    "bealoth",
    "beam",
    "beams",
    "bean",
    "beans",
    "bear",
    "beard",
    "beards",
    "bearers",
    "bearest",
    "beareth",
    "bearing",
    "bears",
    "beast",
    "beast's",
    "beasts",
    "beat",
    "beaten",
    "beatest",
    "beateth",
    "beating",
    "beauteous",
    "beauties",
    "beautified",
    "beautiful",
    "beautify",
    "beauty",
    "bebai",
    "became",
    "becamest",
    "becher",
    "bechorath",
    "beck",
    "beckoned",
    "beckoning",
    "become",
    "becometh",
    "bectileth",
    "bed",
    "bed's",
    "bedad",
    "bedan",
    "bedchamber",
    "bedeiah",
    "beds",
    "bedstead",
    "bee",
    "beeliada",
    "beelsarus",
    "beeltethmus",
    "beelzebub",
    "been",
    "beer",
    "beera",
    "beerah",
    "beerelim",
    "beeri",
    "beerlahairoi",
    "beeroth",
    "beerothite",
    "beerothites",
    "beersheba",
    "bees",
    "beeshterah",
    "beetle",
    "beeves",
    "befall",
    "befallen",
    "befalleth",
    "befell",
    "beforehand",
    "beforetime",
    "beg",
    "began",
    "begannest",
    "begat",
    "beget",
    "begettest",
    "begetteth",
    "beggar",
    "beggar's",
    "beggarly",
    "begged",
    "begging",
    "begin",
    "beginnest",
    "beginneth",
    "beginning",
    "beginnings",
    "begotten",
    "beguile",
    "beguiled",
    "beguiling",
    "begun",
    "behalf",
    "behave",
    "behaved",
    "behaveth",
    "behaviour",
    "beheaded",
    "beheld",
    "behemoth",
    "behind",
    "beholder",
    "beholdest",
    "beholdeth",
    "beholding",
    "behoved",
    "being",
    "bekah",
    "bel",
    "bel's",
    "bela",
    "belah",
    "belaites",
    "belch",
    "belemus",
    "belial",
    "belied",
    "belief",
    "belieth",
    "believe",
    "believed",
    "believers",
    "believest",
    "believeth",
    "believing",
    "bell",
    "bellies",
    "bellow",
    "bellows",
    "bells",
    "belly",
    "belmaim",
    "belmen",
    "belong",
    "belonged",
    "belongest",
    "belongeth",
    "belonging",
    "beloved",
    "beloved's",
    "below",
    "belshazzar",
    "belteshazzar",
    "bemoan",
    "bemoaned",
    "bemoaning",
    "ben",
    "benaiah",
    "benammi",
    "benches",
    "bend",
    "bended",
    "bendeth",
    "bending",
    "beneath",
    "beneberak",
    "benefactors",
    "benefit",
    "benefited",
    "benefits",
    "benejaakan",
    "benevolence",
    "benhadad",
    "benhail",
    "benhanan",
    "beninu",
    "benjamin",
    "benjamin's",
    "benjamite",
    "benjamites",
    "beno",
    "benoni",
    "bent",
    "benzoheth",
    "beon",
    "beor",
    "bera",
    "berachah",
    "berachiah",
    "beraiah",
    "berea",
    "bereave",
    "bereaved",
    "bereaveth",
    "berechiah",
    "bered",
    "beri",
    "beriah",
    "beriites",
    "berites",
    "berith",
    "bernice",
    "berodachbaladan",
    "beroth",
    "berothah",
    "berothai",
    "berothite",
    "berries",
    "beryl",
    "besai",
    "beseech",
    "beseecheth",
    "beseeching",
    "beseemed",
    "beset",
    "beside",
    "besides",
    "besiege",
    "besieged",
    "besieging",
    "besodeiah",
    "besom",
    "besor",
    "besought",
    "best",
    "bestead",
    "bestir",
    "bestow",
    "bestowed",
    "bestoweth",
    "betah",
    "betane",
    "beten",
    "bethabara",
    "bethanath",
    "bethanoth",
    "bethany",
    "betharabah",
    "betharam",
    "betharbel",
    "bethaven",
    "bethazmaveth",
    "bethbaalmeon",
    "bethbarah",
    "bethbasi",
    "bethbirei",
    "bethcar",
    "bethdagon",
    "bethdiblathaim",
    "bethel",
    "bethelite",
    "bethemek",
    "bether",
    "bethesda",
    "bethezel",
    "bethgader",
    "bethgamul",
    "bethhaccerem",
    "bethharan",
    "bethhogla",
    "bethhoglah",
    "bethhoron",
    "bethink",
    "bethjeshimoth",
    "bethjesimoth",
    "bethlebaoth",
    "bethlehem",
    "bethlehemite",
    "bethlehemjudah",
    "bethlomon",
    "bethmaachah",
    "bethmarcaboth",
    "bethmeon",
    "bethnimrah",
    "bethoron",
    "bethpalet",
    "bethpazzez",
    "bethpeor",
    "bethphage",
    "bethphelet",
    "bethrapha",
    "bethrehob",
    "bethsaida",
    "bethsamos",
    "bethsan",
    "bethshan",
    "bethshean",
    "bethshemesh",
    "bethshemite",
    "bethshittah",
    "bethsum",
    "bethsura",
    "bethtappuah",
    "bethuel",
    "bethul",
    "bethulia",
    "bethzur",
    "betime",
    "betimes",
    "betolius",
    "betomasthem",
    "betomestham",
    "betonim",
    "betook",
    "betray",
    "betrayed",
    "betrayer",
    "betrayers",
    "betrayest",
    "betrayeth",
    "betraying",
    "betroth",
    "betrothed",
    "better",
    "bettered",
    "between",
    "betwixt",
    "beulah",
    "bewail",
    "bewailed",
    "bewaileth",
    "beware",
    "bewitched",
    "bewitching",
    "bewray",
    "bewrayeth",
    "beyond",
    "bezai",
    "bezaleel",
    "bezek",
    "bezer",
    "bezeth",
    "biatas",
    "bichri",
    "bid",
    "bidden",
    "biddeth",
    "bidding",
    "bide",
    "bidkar",
    "bier",
    "bigtha",
    "bigthan",
    "bigthana",
    "bigvai",
    "bildad",
    "bileam",
    "bilgah",
    "bilgai",
    "bilhah",
    "bilhan",
    "bill",
    "billows",
    "bilshan",
    "bimhal",
    "bind",
    "bindeth",
    "binding",
    "binea",
    "binnui",
    "bird",
    "bird's",
    "birds",
    "birds'",
    "birsha",
    "birth",
    "birthday",
    "birthright",
    "birzavith",
    "bishlam",
    "bishop",
    "bishoprick",
    "bishops",
    "bit",
    "bite",
    "biteth",
    "bithiah",
    "bithron",
    "bithynia",
    "bitings",
    "bits",
    "bitten",
    "bitter",
    "bitterly",
    "bittern",
    "bitterness",
    "bizjothjah",
    "biztha",
    "black",
    "blacked",
    "blacker",
    "blackish",
    "blackness",
    "blade",
    "blains",
    "blame",
    "blamed",
    "blameless",
    "blaspheme",
    "blasphemed",
    "blasphemer",
    "blasphemers",
    "blasphemest",
    "blasphemeth",
    "blasphemies",
    "blaspheming",
    "blasphemous",
    "blasphemously",
    "blasphemy",
    "blast",
    "blasted",
    "blasting",
    "blastus",
    "blaze",
    "bleating",
    "bleatings",
    "bled",
    "bleed",
    "blemish",
    "blemishes",
    "bless",
    "blessed",
    "blessedness",
    "blessest",
    "blesseth",
    "blessing",
    "blessings",
    "blew",
    "blind",
    "blinded",
    "blindeth",
    "blindfolded",
    "blindness",
    "blood",
    "bloodguiltiness",
    "bloodshed",
    "bloodshedder",
    "bloodshedding",
    "bloodthirsty",
    "bloody",
    "bloomed",
    "blossom",
    "blossomed",
    "blossoms",
    "blot",
    "blotted",
    "blotteth",
    "blotting",
    "blow",
    "bloweth",
    "blowing",
    "blown",
    "blue",
    "blueness",
    "blunt",
    "blush",
    "boanerges",
    "boar",
    "board",
    "boards",
    "boars",
    "boast",
    "boasted",
    "boasters",
    "boastest",
    "boasteth",
    "boasting",
    "boastings",
    "boat",
    "boats",
    "boaz",
    "boccas",
    "bocheru",
    "bochim",
    "bodies",
    "bodily",
    "body",
    "body's",
    "bohan",
    "boil",
    "boiled",
    "boiling",
    "boils",
    "boisterous",
    "bold",
    "boldly",
    "boldness",
    "bolled",
    "bolster",
    "bolt",
    "bolted",
    "bond",
    "bondage",
    "bondmaid",
    "bondmaids",
    "bondman",
    "bondmen",
    "bonds",
    "bondservant",
    "bondservice",
    "bondslave",
    "bondwoman",
    "bondwomen",
    "bone",
    "bones",
    "bonnets",
    "book",
    "books",
    "booth",
    "booths",
    "booties",
    "booty",
    "booz",
    "border",
    "borderers",
    "bordereth",
    "borders",
    "bore",
    "bored",
    "borith",
    "born",
    "borne",
    "borrow",
    "borrowed",
    "borrower",
    "borroweth",
    "borrowing",
    "boscath",
    "bosom",
    "bosor",
    "bosora",
    "bosses",
    "botch",
    "both",
    "bottle",
    "bottles",
    "bottom",
    "bottomless",
    "bottoms",
    "bough",
    "boughs",
    "bought",
    "bound",
    "bounds",
    "bountiful",
    "bountifully",
    "bountifulness",
    "bounty",
    "bow",
    "bowed",
    "bowels",
    "boweth",
    "bowing",
    "bowl",
    "bowls",
    "bowmen",
    "bows",
    "box",
    "boy",
    "boys",
    "bozez",
    "bozkath",
    "bozrah",
    "bracelet",
    "bracelets",
    "bragged",
    "bragging",
    "brags",
    "braided",
    "brake",
    "brakest",
    "bramble",
    "brambles",
    "bran",
    "branch",
    "branches",
    "brand",
    "brandish",
    "brands",
    "brasen",
    "brass",
    "bravely",
    "bravery",
    "brawler",
    "brawlers",
    "brawling",
    "brawls",
    "bray",
    "brayed",
    "breach",
    "breaches",
    "bread",
    "breadth",
    "break",
    "breaker",
    "breakest",
    "breaketh",
    "breaking",
    "breakings",
    "breast",
    "breastplate",
    "breastplates",
    "breasts",
    "breath",
    "breathe",
    "breathed",
    "breatheth",
    "breathing",
    "bred",
    "breeches",
    "breed",
    "breeding",
    "brethren",
    "brethren's",
    "brevity",
    "bribe",
    "bribery",
    "bribes",
    "brick",
    "brickkiln",
    "bricks",
    "bride",
    "bridechamber",
    "bridegroom",
    "bridegroom's",
    "bridegrooms",
    "bridge",
    "bridle",
    "bridles",
    "bridleth",
    "briefly",
    "brier",
    "briers",
    "brigandine",
    "brigandines",
    "bright",
    "brighter",
    "brightness",
    "brim",
    "brimstone",
    "bring",
    "bringers",
    "bringest",
    "bringeth",
    "bringing",
    "brink",
    "brittle",
    "broad",
    "broader",
    "broided",
    "broidered",
    "broiled",
    "broke",
    "broken",
    "brokenfooted",
    "brokenhanded",
    "brokenhearted",
    "brood",
    "brook",
    "brooks",
    "broth",
    "brother",
    "brother's",
    "brotherhood",
    "brotherly",
    "brothers'",
    "brought",
    "broughtest",
    "brow",
    "brown",
    "bruise",
    "bruised",
    "bruises",
    "bruising",
    "bruit",
    "brute",
    "brutish",
    "bucket",
    "buckets",
    "buckle",
    "buckler",
    "bucklers",
    "bud",
    "budded",
    "budding",
    "buds",
    "buffet",
    "buffeted",
    "build",
    "builded",
    "buildedst",
    "builder",
    "builders",
    "buildest",
    "buildeth",
    "building",
    "buildings",
    "built",
    "bukki",
    "bukkiah",
    "bul",
    "bull",
    "bullock",
    "bullock's",
    "bullocks",
    "bulls",
    "bulrush",
    "bulrushes",
    "bulwarks",
    "bunah",
    "bunch",
    "bunches",
    "bundle",
    "bundles",
    "bunni",
    "burden",
    "burdened",
    "burdens",
    "burdensome",
    "burial",
    "buried",
    "buriers",
    "burieth",
    "burn",
    "burned",
    "burneth",
    "burning",
    "burnings",
    "burnished",
    "burnt",
    "burst",
    "bursting",
    "bury",
    "burying",
    "buryingplace",
    "bush",
    "bushel",
    "bushes",
    "bushy",
    "busied",
    "business",
    "busy",
    "busybodies",
    "busybody",
    "butler",
    "butlers",
    "butlership",
    "butter",
    "buttocks",
    "buy",
    "buyer",
    "buyest",
    "buyeth",
    "buying",
    "buz",
    "buzi",
    "buzite",
    "byways",
    "byword",
    "cab",
    "cabbon",
    "cabins",
    "cabul",
    "caddis",
    "cades",
    "cadmiel",
    "caesar",
    "caesar's",
    "caesarea",
    "cage",
    "caiaphas",
    "cain",
    "cainan",
    "cake",
    "cakes",
    "calah",
    "calamities",
    "calamity",
    "calamolalus",
    "calamus",
    "calcol",
    "caldron",
    "caldrons",
    "caleb",
    "caleb's",
    "calebephratah",
    "calf",
    "calf's",
    "calitas",
    "calkers",
    "call",
    "called",
    "calledst",
    "callest",
    "calleth",
    "calling",
    "callisthenes",
    "calm",
    "calneh",
    "calno",
    "calphi",
    "calvary",
    "calve",
    "calved",
    "calves",
    "calveth",
    "camel",
    "camel's",
    "camels",
    "camels'",
    "camest",
    "camon",
    "camp",
    "camped",
    "camphire",
    "camps",
    "can",
    "cana",
    "canaan",
    "canaanite",
    "canaanites",
    "canaanitess",
    "canaanitish",
    "candace",
    "candle",
    "candles",
    "candlestick",
    "candlesticks",
    "cane",
    "canker",
    "cankered",
    "cankerworm",
    "canneh",
    "cannot",
    "canopy",
    "canst",
    "capernaum",
    "capharsalama",
    "caphenatha",
    "caphira",
    "caphthorim",
    "caphtor",
    "caphtorim",
    "caphtorims",
    "cappadocia",
    "captain",
    "captains",
    "captive",
    "captives",
    "captivities",
    "captivity",
    "car",
    "carabasion",
    "carbuncle",
    "carbuncles",
    "carcas",
    "carcase",
    "carcases",
    "carchamis",
    "carchemish",
    "care",
    "careah",
    "cared",
    "careful",
    "carefully",
    "carefulness",
    "careless",
    "carelessly",
    "cares",
    "carest",
    "careth",
    "caria",
    "caring",
    "carmanians",
    "carme",
    "carmel",
    "carmelite",
    "carmelitess",
    "carmi",
    "carmites",
    "carnaim",
    "carnal",
    "carnally",
    "carnion",
    "carpenter",
    "carpenter's",
    "carpenters",
    "carpus",
    "carriage",
    "carriages",
    "carried",
    "carriest",
    "carrieth",
    "carrs",
    "carry",
    "carrying",
    "carshena",
    "cart",
    "carts",
    "cartwheel",
    "carved",
    "carving",
    "carvings",
    "case",
    "casement",
    "cases",
    "casiphia",
    "casleu",
    "casluhim",
    "casphon",
    "casphor",
    "caspis",
    "cassia",
    "cast",
    "castaway",
    "castedst",
    "castest",
    "casteth",
    "casting"
]