const twelveLetters = [
    "alliteration",
    "unidentified",
    "intermittent",
    "exacerbation",
    "independence",
    "commensalism",
    "intelligence",
    "relationship",
    "thanksgiving",
    "professional",
    "organization",
    "sporadically",
    "intimidating",
    "abolitionist",
    "onomatopoeia",
    "appreciation",
    "annunciation",
    "malnutrition",
    "architecture",
    "biodiversity",
    "acceleration",
    "interdiction",
    "trigonometry",
    "communicator",
    "bodybuilding",
    "perspiration",
    "appertaining",
    "resurrection",
    "constipation",
    "civilization",
    "retrocession",
    "expectations",
    "ambidextrous",
    "endoskeleton",
    "hippopotamus",
    "interjection",
    "exasperation",
    "felicitation",
    "abbreviation",
    "voluminosity",
    "rambunctious",
    "colonization",
    "interception",
    "championship",
    "acquaintance",
    "depreciation",
    "consequences",
    "grandparents",
    "refrigerator",
    "cantankerous",
    "kaleidoscope",
    "exhilarating",
    "neighborhood",
    "anticipation",
    "stridulation",
    "electrifying",
    "prescription",
    "condensation",
    "affectionate",
    "philandering",
    "progesterone",
    "pericarditis",
    "veterinarian",
    "alliterative",
    "apperception",
    "surroundings",
    "announcement",
    "predilection",
    "conservation",
    "transmission",
    "echolocation",
    "cartographer",
    "encyclopedia",
    "conservative",
    "hypothalamus",
    "incomparably",
    "supercilious",
    "colonization",
    "agribusiness",
    "blandishment",
    "overwhelming",
    "intellectual",
    "approachable",
    "doppelganger",
    "supernatural",
    "finalization",
    "constitution",
    "absquatulate",
    "reproduction",
    "excruciating",
    "degeneration",
    "amphitheater",
    "headquarters",
    "repossession",
    "reliableness",
    "conditioning",
    "asphyxiation",
    "annihilation",
    "idealization",
    "unattainable",
    "unacceptable",
    "electrolysis",
    "adulteration",
    "menstruation",
    "selflessness",
    "satisfaction",
    "togetherness",
    "sectionalism",
    "ramification",
    "aerodynamics",
    "alliterating",
    "equivocating",
    "equivocation",
    "ratification",
    "heartbreaker",
    "intersection",
    "unemployment",
    "dissertation",
    "preservation",
    "interruption",
    "rejuvenation",
    "astronomical",
    "registration",
    "amortization",
    "recollection",
    "difficulties",
    "immunization",
    "intransigent",
    "intoxication",
    "distillation",
    "handkerchief",
    "accidentally",
    "construction",
    "calisthenics",
    "supervention",
    "extinguisher",
    "pediatrician",
    "photographer",
    "praseodymium",
    "amelioration",
    "effortlessly",
    "testosterone",
    "wretchedness",
    "biochemistry",
    "fenestration",
    "bullfighting",
    "coordination",
    "adjudication",
    "faithfulness",
    "introduction",
    "backstabbing",
    "desalination",
    "globetrotter",
    "brontosaurus",
    "breathtaking",
    "humanitarian",
    "exhilaration",
    "moonlighting",
    "commensurate",
    "Barranquilla",
    "listlessness",
    "ostentatious",
    "unprotesting",
    "ingratiating",
    "stonewalling",
    "accomplished",
    "entertaining",
    "skillfulness",
    "inflammation",
    "preposterous",
    "ascertaining",
    "thunderstorm",
    "anagrammatic",
    "kindergarten",
    "overachiever",
    "academically",
    "apprehension",
    "petrifaction",
    "feminization",
    "pertinacious",
    "accelerating",
    "protuberance",
    "identifiable",
    "backwoodsman",
    "hopelessness",
    "administrate",
    "subconscious",
    "carbohydrate",
    "orthodontics",
    "cancellation",
    "butterscotch",
    "fermentation",
    "enterprising",
    "cosmopolitan",
    "withstanding",
    "decongestant",
    "presentation",
    "complication",
    "transparency",
    "enthusiastic",
    "apprehensive",
    "extravaganza",
    "reabsorption",
    "adaptability",
    "distribution",
    "conquistador",
    "misadventure",
    "impregnation",
    "effervescent",
    "idiosyncrasy",
    "gastrulation",
    "invisibility",
    "abbreviature",
    "tuberculosis",
    "ballottement",
    "anthropology",
    "Christianity",
    "organization",
    "indifference",
    "hypertension",
    "backbreaking",
    "counterpoint",
    "thoroughbred",
    "bacteriology",
    "dodecahedron",
    "notification",
    "disquisition",
    "supplication",
    "appendicitis",
    "urbanization",
    "accentuation",
    "circumstance",
    "illegitimate",
    "articulation",
    "jurisdiction",
    "renunciation",
    "dumbfounding",
    "pacification",
    "incorrigible",
    "intercurrent",
    "proctologist",
    "unfathomable",
    "incineration",
    "tessellation",
    "contribution",
    "happenstance",
    "codification",
    "narcissistic",
    "proclamation",
    "mercantilism",
    "philanthropy",
    "forestalling",
    "confidential",
    "abstruseness",
    "installation",
    "housewarming",
    "questionable",
    "bastardizing",
    "breakthrough",
    "thankfulness",
    "recapitulate",
    "occasionally",
    "dissociation",
    "fibrillation",
    "amalgamation",
    "overstepping",
    "orthodontist",
    "schoolfellow",
    "microcircuit",
    "conversation",
    "cheeseburger",
    "proscription",
    "peacefulness",
    "acculturated",
    "headmistress",
    "invigorating",
    "disingenuous",
    "aeronautical",
    "equalization",
    "sandblasting",
    "illumination",
    "flamethrower",
    "absoluteness",
    "intermission",
    "accouterment",
    "subcutaneous",
    "introjection",
    "preponderate",
    "intoxicating",
    "carpetbagger",
    "peacekeeping",
    "mobilization",
    "broadcasting",
    "auscultation",
    "fainthearted",
    "incomparable",
    "disintegrate",
    "misdirection",
    "amalgamating",
    "compensation",
    "skyrocketing",
    "benefactress",
    "disappearing",
    "apportioning",
    "nonrenewable",
    "conscription",
    "haberdashery",
    "isolationist",
    "adequateness",
    "homelessness",
    "monotheistic",
    "disadvantage",
    "intellection",
    "rheumatology",
    "astonishment",
    "inaccessible",
    "accoutrement",
    "disaccharide",
    "wrongfulness",
    "localization",
    "debilitating",
    "commissioner",
    "divisibility",
    "troublemaker",
    "cohabitation",
    "karyokinesis",
    "straightener",
    "trapshooting",
    "embezzlement",
    "unimaginable",
    "extroversion",
    "acquiescence",
    "friendliness",
    "confirmation",
    "asynchronous",
    "dehumidifier",
    "disclamation",
    "feminization",
    "stockjobbing",
    "uglification",
    "authenticate",
    "dishonorable",
    "irresolution",
    "incontinence",
    "fabulousness",
    "exaggerating",
    "ministration",
    "canonization",
    "phospholipid",
    "accompanying",
    "entrepreneur",
    "Saskatchewan",
    "characterize",
    "surveillance",
    "fireproofing",
    "overreaction",
    "calumniation",
    "nomenclature",
    "unattractive",
    "apprehending",
    "anteposition",
    "amortization",
    "decapitation",
    "brinkmanship",
    "disciplinary",
    "frontiersman",
    "undetectable",
    "additionally",
    "monetization",
    "admonishment",
    "compromising",
    "characterize",
    "choreography",
    "graciousness",
    "illustration",
    "silviculture",
    "antemeridian",
    "sharecropper",
    "disregarding",
    "consecration",
    "blindfolding",
    "disagreement",
    "horticulture",
    "disconsolate",
    "boastfulness",
    "irregardless",
    "contemptible",
    "anticyclonic",
    "counterclaim",
    "vociferating",
    "gynecologist",
    "incalculable",
    "sidestepping",
    "blueprinting",
    "anathematize",
    "exaggeration",
    "lollygagging",
    "presumptuous",
    "discrediting",
    "cardiologist",
    "chemotherapy",
    "repercussion",
    "sternutation",
    "incandescent",
    "flexibleness",
    "simultaneity",
    "optimization",
    "demodulation",
    "chalcanthite",
    "imperfection",
    "historically",
    "congeniality",
    "claudication",
    "bachelorship",
    "proportional",
    "unconsenting",
    "ossification",
    "kleptomaniac",
    "thoroughness",
    "precognition",
    "conservatory",
    "mobilization",
    "immeasurable",
    "steeplechase",
    "educationist",
    "steeplechase",
    "educationist",
    "tranquilizer",
    "dermabrasion",
    "regeneration",
    "discourteous",
    "instrumental",
    "denunciation",
    "melodramatic",
    "stichomythia",
    "extravagance",
    "insemination",
    "chalcopyrite",
    "cantillating",
    "contumacious",
    "appendectomy",
    "interlocking",
    "tribulations",
    "heartwarming",
    "alimentation",
    "deflagration",
    "verification",
    "contemplated",
    "afterthought",
    "anticipating",
    "knightliness",
    "arthrography",
    "penalization",
    "overpowering",
    "fluorination",
    "oscillometer",
    "sustentation",
    "malcontented",
    "unionization",
    "psychologist",
    "rhythmically",
    "sacrilegious",
    "preponderant",
    "straightness",
    "obliteration",
    "Indianapolis",
    "volunteering",
    "belligerence",
    "remonstrance",
    "segmentation",
    "anamorphosis",
    "blacktopping",
    "disbelieving",
    "invigilation",
    "earthshaking",
    "augmentation",
    "lighthearted",
    "preservative",
    "unscrupulous",
    "psychiatrist",
    "intertwining",
    "levorotation",
    "microbiology",
    "safecracking",
    "deactivation",
    "anecdotalist",
    "enlightening",
    "quintessence",
    "celebrations",
    "paraphrasing",
    "forgathering",
    "glockenspiel",
    "incrustation",
    "purificatory",
    "conciliation",
    "pigmentation",
    "availability",
    "facilitation",
    "mademoiselle",
    "intimidation",
    "flocculation",
    "osteoporosis",
    "incompletion",
    "highlighting",
    "bewilderment",
    "unachievable",
    "capitulation",
    "insurrection",
    "conveyancing",
    "indiscretion",
    "demoralizing",
    "brainwashing",
    "artificially",
    "potentiation",
    "disqualified",
    "astrophysics",
    "carelessness",
    "equalization",
    "astrophysics",
    "interstellar",
    "consummation",
    "psephologist",
    "urbanization",
    "absorptivity",
    "imprisonment",
    "infiltration",
    "lovesickness",
    "polarization",
    "constricting",
    "misconceived",
    "congregation",
    "nightcrawler",
    "subscription",
    "inactivation",
    "consultation",
    "instructions",
    "disconnected",
    "humanization",
    "supercharger",
    "aerodynamics",
    "banderillero",
    "dispensation",
    "chiropractic",
    "regenerating",
    "heteroplasty",
    "facilitating",
    "astronautics",
    "polysyllabic",
    "nevertheless",
    "disinfectant",
    "adorableness",
    "purification",
    "agglomerated",
    "mispronounce",
    "allusiveness",
    "recklessness",
    "malformation",
    "gynecomastia",
    "bloodthirsty",
    "genuflection",
    "misinforming",
    "defenestrate",
    "monetization",
    "authenticity",
    "irresistible",
    "hypoglycemia",
    "pharmacology",
    "upholstering",
    "astrobiology",
    "hospitalized",
    "vitalization",
    "childbearing",
    "racketeering",
    "emasculation",
    "clairvoyance",
    "aestheticism",
    "dissociative",
    "exacerbating",
    "perturbation",
    "metalanguage",
    "appropriator",
    "thermocouple",
    "antagonistic",
    "appropriated",
    "aristocratic",
    "propitiation",
    "eccentricity",
    "entanglement",
    "deregulation",
    "domesticated",
    "quantization",
    "ailurophobia",
    "truthfulness",
    "liquefaction",
    "orchestrated",
    "aggressively",
    "tracheostomy",
    "acquisitions",
    "dwarfishness",
    "counterforce",
    "capabilities",
    "confusedness",
    "timorousness",
    "sleepwalking",
    "obsolescence",
    "impoliteness",
    "gracefulness",
    "collywobbles",
    "reservations",
    "protactinium",
    "snobbishness",
    "abolitionism",
    "conservatism",
    "bloodletting",
    "sarsaparilla",
    "enormousness",
    "cunnilinctus",
    "hyperacidity",
    "breadwinning",
    "condemnation",
    "hallucinogen",
    "manufacturer",
    "distributive",
    "compensating",
    "minification",
    "blackballing",
    "interminable",
    "fundamentals",
    "commonwealth",
    "degenerating",
    "accumulation",
    "triplication",
    "youthfulness",
    "alkalization",
    "thermosphere",
    "ambassadress",
    "inauguration",
    "duodecillion",
    "accommodated",
    "vasodilation",
    "indefeasible",
    "antediluvian",
    "luminescence",
    "endoskeleton",
    "separateness",
    "omnipresence",
    "personifying",
    "interweaving",
    "congratulate",
    "potentiality",
    "befuddlement",
    "convincingly",
    "tredecillion",
    "fraternalism",
    "geographical",
    "intervention",
    "communicable",
    "aerification",
    "nasalization",
    "accouchement",
    "unsteadiness",
    "beseechingly",
    "intercession",
    "breathalyzer",
    "chalcography",
    "absentminded",
    "protectorate",
    "annihilating",
    "skullduggery",
    "alphabetical",
    "geopolitical",
    "immortalized",
    "beggarliness",
    "commendation",
    "reimposition",
    "idealization",
    "receptionist",
    "rejuvenating",
    "apiculturist",
    "geochemistry",
    "lifelessness",
    "malversation",
    "heliocentric",
    "polarization",
    "immoderation",
    "unsurprising",
    "incompetence",
    "carbonaceous",
    "advisability",
    "postprandial",
    "variableness",
    "dilapidation",
    "multiplicand",
    "attorneyship",
    "snowmobiling",
    "matronliness",
    "arrangements",
    "snaggletooth",
    "presentiment",
    "breathlessly",
    "appurtenance",
    "postgraduate",
    "undemocratic",
    "transforming",
    "somnambulist",
    "immunization",
    "agricultural",
    "sinisterness",
    "scrutinizing",
    "backgrounder",
    "propagandist",
    "escapologist",
    "continuously",
    "evisceration",
    "domesticable",
    "abstractness",
    "uncontrolled",
    "vilification",
    "undercurrent",
    "longstanding",
    "bibliography",
    "sharpshooter",
    "ineradicable",
    "streamlining",
    "disciplining",
    "hyperthermia",
    "insufferable",
    "periphrastic",
    "continuation",
    "excruciation",
    "appreciative",
    "cavalierness",
    "investigator",
    "reproductive",
    "helplessness",
    "stratosphere",
    "irregularity",
    "circumcision",
    "finalization",
    "generousness",
    "disobedience",
    "chromatogram",
    "newspaperman",
    "stubbornness",
    "psychologism",
    "clownishness",
    "asphyxiating",
    "mathematical",
    "metropolitan",
    "unconformity",
    "rationalness",
    "deceleration",
    "delimitation",
    "somnambulant",
    "accommodator",
    "macronucleus",
    "disapproving",
    "dimensioning",
    "compunctious",
    "acknowledged",
    "deregulating",
    "blabbermouth",
    "hairdressing",
    "polytheistic",
    "cryptography",
    "archeologist",
    "preparedness",
    "watchfulness",
    "councilwoman",
    "irreversible",
    "precancerous",
    "sympathizing",
    "beleaguering",
    "predigestion",
    "pitilessness",
    "aestheticism",
    "inadmissible",
    "phagocytosis",
    "warmongering",
    "inconvenient",
    "fruitfulness",
    "romantically",
    "disheartened",
    "frictionless",
    "irrespective",
    "paraphrastic",
    "luminiferous",
    "contemporary",
    "pheidippides",
    "motorization",
    "bronchoscope",
    "anaesthetist",
    "reticulation",
    "labyrinthine",
    "biographical",
    "arborization",
    "promulgation",
    "contaminated",
    "despairingly",
    "ticklishness",
    "psychobabble",
    "pleasantness",
    "unremarkable",
    "underselling",
    "lonesomeness",
    "dishevelment",
    "amicableness",
    "resettlement",
    "vocalization",
    "malocclusion",
    "disagreeable",
    "productivity",
    "exasperating",
    "spirituality",
    "exploitation",
    "illuminating",
    "caricaturist",
    "trendsetting",
    "sonorousness",
    "speechwriter",
    "hysterectomy",
    "ethnocentric",
    "illiberality",
    "incapacitate",
    "battlemented",
    "borosilicate",
    "adulterating",
    "appositional",
    "penalization",
    "constipating",
    "exchangeable",
    "chiropractor",
    "propaedeutic",
    "solicitation",
    "advantageous",
    "oscilloscope",
    "unconvincing",
    "articulating",
    "indeliberate",
    "backwardness",
    "antifriction",
    "sportscaster",
    "irremediable",
    "legalization",
    "deuteranopia",
    "prerendering",
    "anticipatory",
    "burglarproof",
    "vainglorious",
    "negativeness",
    "convalescing",
    "absolutistic",
    "modification",
    "tactlessness",
    "musicologist",
    "inconsolable",
    "crosscurrent",
    "undergarment",
    "regurgitated",
    "postmistress",
    "practicality",
    "blissfulness",
    "anthelmintic",
    "reciprocator",
    "unhesitating",
    "theorization",
    "unapologetic",
    "speakerphone",
    "accordionist",
    "intracranial",
    "motivational",
    "delamination",
    "displacement",
    "buccaneering",
    "lachrymation",
    "countrywoman",
    "malnourished",
    "conglomerate",
    "almightiness",
    "mercifulness",
    "quadrangular",
    "brilliantine",
    "intensifying",
    "misalignment",
    "indefensible",
    "deracination",
    "controllable",
    "privateering",
    "disinflation",
    "contrariwise",
    "hemorrhaging",
    "concomitance",
    "monopolizing",
    "amenableness",
    "metaphysical",
    "spectroscope",
    "trephination",
    "forthrightly",
    "chromosphere",
    "interpleader",
    "appassionato",
    "commencement",
    "anesthetized",
    "subservience",
    "undercoating",
    "inarticulate",
    "functionally",
    "incapability",
    "constipation",
    "burglarizing",
    "disorientate",
    "unnoticeable",
    "heartrending",
    "micronucleus",
    "adventitious",
    "ruthlessness",
    "appointments",
    "noncompliant",
    "recuperation",
    "irredeemable",
    "flagellation",
    "imperception",
    "impersonator",
    "autodidactic",
    "rededication",
    "orchestrator",
    "bureaucratic",
    "decisiveness",
    "reevaluation",
    "perviousness",
    "econometrics",
    "criticalness",
    "antifeminism",
    "insufficient",
    "implantation",
    "navigability",
    "immobilizing",
    "northwestern",
    "prepossessed",
    "disgorgement",
    "stepdaughter",
    "providential",
    "ambitendency",
    "incendiarism",
    "frankincense",
    "phonetically",
    "denomination",
    "provisioning",
    "appoggiatura",
    "triglyceride",
    "separability",
    "cartographic",
    "lifelikeness",
    "invalidation",
    "vociferation",
    "housekeeping",
    "christianism",
    "reactivation",
    "unforgivable",
    "wainscotting",
    "wrathfulness",
    "manslaughter",
    "inconsistent",
    "presidential",
    "counteroffer",
    "motorboating",
    "hindquarters",
    "triumphantly",
    "untimeliness",
    "rechargeable",
    "labyrinthian",
    "rightfulness",
    "unsearchable",
    "inhabitation",
    "solarization",
    "periodically",
    "speciousness",
    "affectedness",
    "abolitionary",
    "churchwarden",
    "legalization",
    "revitalizing",
    "emotionalism",
    "memorization",
    "paleontology",
    "divarication",
    "uninterested",
    "charlatanism",
    "profoundness",
    "billingsgate",
    "minimization",
    "catastrophic",
    "constringent",
    "luncheonette",
    "tirelessness",
    "childishness",
    "technicality",
    "ingeminating",
    "demoralizing",
    "macroclimate",
    "reductionism",
    "smallholding",
    "biomechanics",
    "entomologist",
    "clannishness",
    "spellbinding",
    "gratefulness",
    "convulsively",
    "obstetrician",
    "paradisiacal",
    "asymptomatic",
    "novelization",
    "weightlifter",
    "invertebrate",
    "inexplicable",
    "undiscerning",
    "whitewashing",
    "gorgeousness",
    "spokesperson",
    "cardiomegaly",
    "achlorhydria",
    "chesterfield",
    "exterminator",
    "proverbially",
    "amateurishly",
    "outstretched",
    "contestation",
    "insecureness",
    "agglutinogen",
    "expansionism",
    "conformation",
    "intermediary",
    "interference",
    "cachinnation",
    "irrefragable",
    "antifeminist",
    "coincidental",
    "disaffection",
    "electroshock",
    "biosynthesis",
    "obstreperous",
    "reinvigorate",
    "octogenarian",
    "misstatement",
    "interrogator",
    "unsatisfying",
    "spiritedness",
    "flawlessness",
    "intersecting",
    "faultfinding",
    "underpinning",
    "repatriation",
    "stereopticon",
    "recognizance",
    "uncluttering",
    "unsavoriness",
    "indistinctly",
    "habitualness",
    "battleground",
    "phenomenally",
    "antipathetic",
    "collaborator",
    "salutatorian",
    "multiplicity",
    "canonization",
    "dissatisfied",
    "commissioned",
    "thoughtfully",
    "laryngoscope",
    "irritability",
    "irritability",
    "spitefulness",
    "valuableness",
    "despoliation",
    "antithetical",
    "prophylactic",
    "appositeness",
    "minimization",
    "discouraging",
    "maintainable",
    "essentialist",
    "transmitting",
    "theorization",
    "pumpernickel",
    "wholehearted",
    "thoroughfare",
    "positiveness",
    "accelerative",
    "indebtedness",
    "manifoldness",
    "microsurgery",
    "retrenchment",
    "distributary",
    "pathogenesis",
    "humanization",
    "comprehended",
    "clapperboard",
    "checkerberry",
    "goldbricking",
    "deliberation",
    "localization",
    "broncobuster",
    "heterozygous",
    "premeditated",
    "protestation",
    "quantization",
    "straightedge",
    "belletristic",
    "effervescing",
    "oceanography",
    "blatherskite",
    "shamefacedly",
    "disorganized",
    "agreeability",
    "sloganeering",
    "alphabetizer",
    "slenderizing",
    "familiarness",
    "apostrophize",
    "vaporization",
    "electrolytic",
    "stockbroking",
    "crisscrossed",
    "homesickness",
    "estrangement",
    "theoretician",
    "intermediate",
    "iconoclastic",
    "reprehension",
    "substantiate",
    "decomposable",
    "irascibility",
    "consequently",
    "delightfully",
    "mindlessness",
    "epidemiology",
    "infringement",
    "unreflecting",
    "phototropism",
    "catachrestic",
    "columniation",
    "pigeonholing",
    "diamagnetism",
    "conventional",
    "credentialed",
    "oppositeness",
    "endangerment",
    "sensorimotor",
    "adjectivally",
    "intelligible",
    "geophysicist",
    "unmanageable",
    "slovenliness",
    "abrasiveness",
    "paramagnetic",
    "undiscovered",
    "fecklessness",
    "americanized",
    "clairaudient",
    "remuneration",
    "biophysicist",
    "abstemiously",
    "vaticination",
    "superhighway",
    "diabolically",
    "antiaircraft",
    "unionization",
    "interdicting",
    "canalization",
    "perpetuating",
    "prolongation",
    "digitization",
    "autoimmunity",
    "intercepting",
    "perpetration",
    "galvanometer",
    "adaptational",
    "disappointed",
    "proprietress",
    "successfully",
    "ordinariness",
    "covetousness",
    "intrauterine",
    "notarization",
    "shortsighted",
    "stenographer",
    "hypothetical",
    "articulatory",
    "unmistakable",
    "allegorizing",
    "recollecting",
    "anchorperson",
    "respectfully",
    "stereophonic",
    "penitentiary",
    "longshoreman",
    "committeeman",
    "attributable",
    "rememberable",
]

export default twelveLetters